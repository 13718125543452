import { gql } from "@apollo/client";

export const GET_CREDIT_VOUCHER_CLAIMS = gql`
  query AdminFindClaimedVouchers($start: Int, $limit: Int, $where: JSON) {
    adminFindClaimedVouchers(start: $start, limit: $limit, where: $where) {
      id
      claimedById
      voucherId
      status
      createdAt
    }
  }
`;
