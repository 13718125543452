import { gql } from "@apollo/client";

export const GET_CREDIT_WALLET_HISTORIES = gql`
  query AdminFindCreditWalletHistories($start: Int, $limit: Int, $where: JSON) {
    adminFindCreditWalletHistories(
      start: $start
      limit: $limit
      where: $where
    ) {
      id
      senderWalletId
      recepientWalletId
      amount
      type
      description
      createdAt
    }
  }
`;
