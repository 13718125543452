import { TextInput, useDebouncedState } from "@pankod/refine-mantine";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { GET_CREDIT_WALLETS } from "graphql/credits/credit-wallets/get-credit-wallets.query";
import { NewCustomTable } from "components/NewCustomTable";

export type ICreditWallet = {
  id: string;
  accountId: string;
  balance: number;
  createdAt: string;
};

const CreditWalletFields: ICreditWallet = {
  id: "",
  accountId: "",
  balance: 0,
  createdAt: "",
};

const creditWalletFieldKeys = Object.keys(CreditWalletFields).map((key) => {
  return key;
});

export const CreditWalletList: React.FC<IResourceComponentsProps> = () => {
  const [search, setSearch] = useDebouncedState<string | undefined>("", 400);

  return (
    <NewCustomTable
      identifier="id"
      fields={creditWalletFieldKeys}
      name="adminFindCreditWallets"
      query={GET_CREDIT_WALLETS}
      variables={{
        start: 0,
        limit: 10,
      }}
      filters={{
        where: { search },
      }}
    >
      <>
        <TextInput
          className="mb-2"
          type="text"
          placeholder="search credit wallet by phone number"
          onChange={(e) => setSearch(e.currentTarget.value.trim())}
        />
      </>
    </NewCustomTable>
  );
};
