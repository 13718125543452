import { TextInput, useDebouncedState } from "@pankod/refine-mantine";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { NewCustomTable } from "components/NewCustomTable";
import { GET_CREDIT_VOUCHER_CLAIMS } from "graphql/credits/credit-voucher-claims/get-credit-voucher-claims.query";

enum CreditVoucherClaimStatus {
  UNUSED,
  REDEEMED
}

export type ICreditVoucherClaim = {
  id: string;
  claimedById: string;
  voucherId: string;
  status: CreditVoucherClaimStatus;
  createdAt: string;
};

const CreditVoucherClaimFields: ICreditVoucherClaim = {
  id: "",
  claimedById: "",
  voucherId: "",
  status: CreditVoucherClaimStatus.UNUSED,
  createdAt: "",
};

const creditVoucherClaimFieldKeys = Object.keys(CreditVoucherClaimFields).map((key) => {
  return key;
});

export const CreditVoucherClaimList: React.FC<IResourceComponentsProps> = () => {
  const [search, setSearch] = useDebouncedState<string | undefined>("", 400);

  return (
    <NewCustomTable
      identifier="id"
      fields={creditVoucherClaimFieldKeys}
      name="adminFindClaimedVouchers"
      query={GET_CREDIT_VOUCHER_CLAIMS}
      variables={{
        start: 0,
        limit: 10,
      }}
      filters={{
        where: { search },
      }}
    >
      <>
        <TextInput
          className="mb-2"
          type="text"
          placeholder="search by account phone number"
          onChange={(e) => setSearch(e.currentTarget.value.trim())}
        />
      </>
    </NewCustomTable>
  );
};
