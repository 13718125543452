import { gql } from "@apollo/client";

export const GET_CREDIT_WALLETS = gql`
  query AdminFindCreditWallets($start: Int, $limit: Int, $where: JSON) {
    adminFindCreditWallets(start: $start, limit: $limit, where: $where) {
      id
      accountId
      balance
      createdAt
    }
  }
`;
