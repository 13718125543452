import { IResourceComponentsProps } from "@pankod/refine-core";
import { NewCustomTable } from "components/NewCustomTable";
import { GET_VOUCHERS } from "graphql/credits/credit-vouchers/credit-vouchers.query";

export type ICreditVoucher = {
  id: string;
  name: string;
  slug: string;
  service: string;
  creditPrice: number;
  discount: number;
  createdAt: string;
};

const CreditVoucherFields: ICreditVoucher = {
  id: "",
  name: "",
  slug: "",
  service: "",
  creditPrice: 0,
  discount: 0,
  createdAt: new Date().toISOString(),
};

const creditVoucherFieldKeys = Object.keys(CreditVoucherFields).map((key) => {
  return key;
});

export const CreditVoucherList: React.FC<IResourceComponentsProps> = () => {
  return (
    <NewCustomTable
      identifier="id"
      fields={creditVoucherFieldKeys}
      name="vouchers"
      query={GET_VOUCHERS}
    />
  );
};
