import { TextInput, useDebouncedState } from "@pankod/refine-mantine";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { NewCustomTable } from "components/NewCustomTable";
import { GET_CREDIT_WALLET_HISTORIES } from "graphql/credits/credit-wallet-histories/get-credit-wallet-histories.query";

enum CreditWalletHistoryType {
  REFERRAL,
  WITHDRAWAL,
  DEPOSIT,
}

export type ICreditWalletHistory = {
  id: string;
  senderWalletId: string;
  recepientWalletId: string;
  type: CreditWalletHistoryType;
  description: string;
  amount: number;
  createdAt: string;
};

const CreditWalletHistoryFields: ICreditWalletHistory = {
  id: "",
  senderWalletId: "",
  recepientWalletId: "",
  type: CreditWalletHistoryType.REFERRAL,
  description: "",
  amount: 0,
  createdAt: "",
};

const creditWalletHistoryFieldKeys = Object.keys(CreditWalletHistoryFields).map((key) => {
  return key;
});

export const CreditWalletHistoryList: React.FC<IResourceComponentsProps> = () => {
  const [search, setSearch] = useDebouncedState<string | undefined>("", 400);

  return (
    <NewCustomTable
      identifier="id"
      fields={creditWalletHistoryFieldKeys}
      name="adminFindCreditWalletHistories"
      query={GET_CREDIT_WALLET_HISTORIES}
      variables={{
        start: 0,
        limit: 10,
      }}
      filters={{
        where: { search },
      }}
    >
      <>
        <TextInput
          className="mb-2"
          type="text"
          placeholder="search by account phone number"
          onChange={(e) => setSearch(e.currentTarget.value.trim())}
        />
      </>
    </NewCustomTable>
  );
};
